import React from "react"

import simpleFooterStyles from "../styles/simple-footer.module.scss"

const SimpleFooter = () => {
    return (
        <footer className={simpleFooterStyles.container}>
            <p>© 2020, Hungry Turtle</p>
            <p>Impressum</p>
            <p>Datenschutz</p>
            <p>AGB</p>
        </footer>
    )
}

export default SimpleFooter