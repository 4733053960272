import React, { useRef } from "react"

import { useInView } from 'react-intersection-observer'
import { InView } from 'react-intersection-observer'

import { Navbar, Nav } from 'react-bootstrap';
import { Link } from "gatsby-plugin-intl"

import Language from "../components/language.js"
import Image from "../components/image"
import headerStyles from "../styles/header.module.scss"



const Header = () => {

    // const [ref, inView, entry] = useInView({
    //   /* Optional options */
    //   threshold: 0,
    // })

  return (
    <header className={headerStyles.header}>
    {/* <InView className={headerStyles.header} as="div" onChange={(inView, entry) => console.log('Header Inview:', inView)}> */}


      <Navbar expand="md" variant="dark" className={headerStyles.navbar}  
          // backgroundColor={navBackground ? 'white' : 'transparent'}
          // style={{ transition: '1s ease' }}
      >
        <Navbar.Brand className={headerStyles.logoContainer}>
        <Link to="/">
          <Image />    
        </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarResponsive"/>
        <Navbar.Collapse id="navbarResponsive" bg="dark">
          <Nav as="ul" className="ml-auto">
            <Nav as="li" className={headerStyles.navItem}>
              <Link 
                className={headerStyles.navItem}
                activeClassName={headerStyles.activeNavItem}
                to="/food"
              >
                Order Now
              </Link>
            </Nav>
            <Nav as="li" className={headerStyles.navItem}>
              <Link
                className={headerStyles.navItem}
                activeClassName={headerStyles.activeNavItem}
                to="/food"
              >
                Eat
              </Link>
            </Nav>
            <Nav as="li" className={headerStyles.navItem}>
              <Link
                className={headerStyles.navItem}
                activeClassName={headerStyles.activeNavItem}
                to="/shop"
              >
                Shop
              </Link>
            </Nav>

              <Language />
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    {/* </InView> */}
    </header>
  )
}

export default Header
