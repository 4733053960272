// import React from "react"
import React, { useRef } from "react"

import {Helmet} from "react-helmet";
import 'bootstrap/dist/css/bootstrap.min.css';
import { InView } from 'react-intersection-observer'
import { useInView } from 'react-intersection-observer'


import SEO from "./seo"
import Header from "./header"
import SimpleFooter from "./simple-footer"
// import "../styles/reset.scss"
import layoutContentStyles from "../styles/layout-content.module.scss"
import "../styles/vars.scss"

const LayoutContent = props => {

  // const options = {
  //   root: null,
  //   rootMargin: '0px',
  //   threshold: [0.98, 0.99, 1]
  // }
  const [ref, inView, entry] = useInView({
    /* Optional options */
    threshold: 0.1,
  })

  // const callback = (entries, observer) => { 
  //   entries.forEach(entry => {
  //     console.log ("Inview: ");
  //     // Each entry describes an intersection change for one observed
  //     // target element:
  //     //   entry.boundingClientRect
  //     //   entry.intersectionRatio
  //     //   entry.intersectionRect
  //     //   entry.isIntersecting
  //     //   entry.rootBounds
  //     //   entry.target
  //     //   entry.time
  //   });
  // };
  // const observer = new IntersectionObserver(callback, options);
  // observer.observe(ref);

  return (
    <div className={layoutContentStyles.main}>
      <SEO title="We love Burritos" />

      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" value="noindex,nofollow" />  {/* TODO: delete for google indexing  */}
        {/* <script src="https://identity.netlify.com/v1/netlify-identity-widget.js"></script> */}
      </Helmet>

      <div className={layoutContentStyles.header} ref={ref}>
        <Header />
      </div>
      {inView ? console.log("true") : console.log("false")}
      <div className={layoutContentStyles.content} >
      {/* <InView className={layoutContentStyles.content} as="div" onChange={(inView, entry) => console.log('Inview:', inView)}> */}

        {props.children}
      {/* </InView> */}
      </div>
      <div className={layoutContentStyles.footer}>
        <SimpleFooter />
      </div> 
    </div>
  )
}


export default LayoutContent

